export default {
  cake: {
    56: '0x05995a068bdac17c582eC75AB46bb8e7394be1d9',
    97: '',
  },
  masterChef: {
    56: '0x106701bAF5F4ca518BAdFc4E72e387811CedF2FF',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  ean: {
    56: '0xf711d680dd3e987b6bd24253931e5495c954b557',
    97: '',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
